import { render, staticRenderFns } from "./createBanner.vue?vue&type=template&id=c50a167c&scoped=true&"
import script from "./createBanner.vue?vue&type=script&lang=js&"
export * from "./createBanner.vue?vue&type=script&lang=js&"
import style0 from "./createBanner.vue?vue&type=style&index=0&id=c50a167c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c50a167c",
  null
  
)

export default component.exports